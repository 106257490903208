import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'serviceWorker'
import AuthAmplifyApp from './auth/AuthAmplifyApp'
import Amplify, { Auth, API } from 'aws-amplify'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_NAME_ENDPOINT,
        endpoint: process.env.REACT_APP_BASE_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          }
        },
      },
    ],
  },
})

ReactDOM.render(
  <StrictMode>
    <AuthAmplifyApp />
  </StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
