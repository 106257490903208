// 0    : blue   (hsl(240, 100%, 50%))
// 0.25 : cyan   (hsl(180, 100%, 50%))
// 0.5  : green  (hsl(120, 100%, 50%))
// 0.75 : yellow (hsl(60, 100%, 50%))
// 1    : red    (hsl(0, 100%, 50%))

const heatMapColorValue = (value) => {
  const h = (1.0 - value) * 240
  return `hsl(${h}, 100%, 50%)`
}

const calculateRangeColor = (value, minValue, maxValue) => {
  if(value > maxValue) return 1;
  if(value < minValue) return 0;
  return (value * 1) / maxValue
}

const bgColorBarChart = (position) =>
  `linear-gradient(to ${position}, hsl(0, 100%, 50%), hsl(60, 100%, 50%), hsl(120, 100%, 50%), hsl(180, 100%, 50%), hsl(240, 100%, 50%))`

export { heatMapColorValue, calculateRangeColor, bgColorBarChart }
