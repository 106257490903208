import { lazy } from 'react'
import { retry } from 'utils'

const Login = lazy(() => retry(() => import('pages/Login')))
// const Home = lazy(() => retry(() => import('pages/Home')))
const Dashboard = lazy(() => retry(() => import('pages/Dashboard')))
const DashboardDetail = lazy(() =>
  retry(() => import('pages/Dashboard/DashboardDetail')),
)
const NotFound = lazy(() => retry(() => import('pages/NotFound')))

const routes = [
  { pathname: '/', isPublic: true, component: Login },
  // { pathname: '/home', isPublic: false, component: Home },
  { pathname: '/dashboard', isPublic: false, component: Dashboard },
  { pathname: '/dashboard/:id', isPublic: false, component: DashboardDetail },
]

export default routes
export { NotFound }
