/**
 * Routes
 *
 * Main router of the application.
 */

import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import routes, { NotFound } from './routes'

export default function Routes() {
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Switch>
        {routes.map(({ pathname, isPublic, component }) => {
          if (isPublic) {
            return (
              <PublicRoute
                key={pathname}
                restricted
                exact
                path={pathname}
                component={component}
              />
            )
          }

          return (
            <PrivateRoute
              key={pathname}
              exact
              path={pathname}
              component={component}
            />
          )
        })}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}
