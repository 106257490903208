import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const ListItemLink = ({ icon, primary, to, selected }) => {
  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  )

  const Icon = icon

  return (
    <ListItem button component={renderLink} selected={selected}>
      {icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={primary} />
    </ListItem>
  )
}

ListItemLink.propTypes = {
  icon: PropTypes.elementType.isRequired,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default ListItemLink
