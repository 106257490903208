import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Toolbar, Typography, Divider, List } from '@material-ui/core'
import ListItems from '../ListItems'

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: 'auto',
  },
  toolbar: {
    background: '#834A20',
    color: theme.palette.common.white,
  },
}))

const SideBarDrawer = () => {
  const classes = useStyles()

  return (
    <>
      <Toolbar classes={{ root: classes.toolbar }}>
        <Typography variant='h6' noWrap style={{ fontWeight: 'bold' }}>
          Sistema EVA
        </Typography>
      </Toolbar>
      <Divider />
      <div className={classes.drawerContainer}>
        <List>
          <ListItems />
        </List>
      </div>
    </>
  )
}

export default SideBarDrawer
