import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import ListItemLink from '../ListItemLink'
import menus from '../menus'

const useStyles = makeStyles((theme) => ({
  listItemClass: {
    fontWeight: 'bold',
    background: '#717171',
    boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
  },
}))

const ListItems = () => {
  const classes = useStyles()

  const location = useLocation()
  const [path, setPath] = useState('')

  useEffect(() => {
    setPath(location.pathname)
  }, [location, setPath])

  const activetRoute = (route) => route === path

  return (
    <>
      <div
        style={{
          boxShadow: '0px 4px 4px rgba(0,0,0,0.25)',
          marginBottom: '5px',
        }}>
        {menus.map(({ pathname, name, icon }) => (
          <ListItemLink
            classes={classes.listItemClass}
            key={pathname}
            to={pathname}
            primary={name}
            icon={icon}
            selected={activetRoute(pathname)}
          />
        ))}
      </div>
      <AmplifySignOut style={{ marginTop: '5px' }} />
    </>
  )
}

export default ListItems
