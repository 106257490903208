import React from 'react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import Layout from 'components/Layout'
import theme from 'theme'

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Layout />
    </MuiThemeProvider>
  )
}
