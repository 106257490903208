/**
 * Layout
 *
 * In this file the different types of Layouts interact.
 */

import React from 'react'
import Routes from 'components/Routes'
import PrivateLayout from './PrivateLayout'

export default function Layout() {
  return (
    <PrivateLayout>
      <Routes />
    </PrivateLayout>
  )
}
