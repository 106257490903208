/**
 * Private Layout
 *
 * In this file the Private Layout of the application is created, this
 * will be the view that the user will see in the case of an application
 * that has authentication and the user is logged in.
 *
 * In general, its use is based on assembling repetitive components such
 * as: <Sidebar />, <Header />, <Footer />.
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Toolbar, CssBaseline } from '@material-ui/core'
import AppBar from 'components/AppBar'
import SideBar from 'components/SideBar'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

const PrivateLayout = ({ children }) => {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar handleDrawerToggle={handleDrawerToggle} />
      <SideBar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <main className={classes.content}>
        <Toolbar />
        <Container maxWidth='xl' className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  )
}

PrivateLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PrivateLayout
