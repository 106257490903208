import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar as MUIAppBar,
  Toolbar,
  IconButton,
  Hidden,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logoKreaHorizontal from '../../constants/images/LogoKreaHorizontal.png'

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#834A20',
    zIndex: theme.zIndex.drawer + 1,
    left: '180px',
    [theme.breakpoints.down('sm')]: {
      left: '0px',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
}))

const AppBar = ({ handleDrawerToggle }) => {
  const classes = useStyles()

  return (
    <MUIAppBar
      position='fixed'
      className={classes.appBar}
      style={{ marginLeft: '0px' }}>
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Hidden smDown>
          <p
            style={{
              margin: '0px',
              paddingLeft: '5px',
              fontSize: '18px',
              fontWeight: '300',
            }}>
            Sistema de monitoreo de cortocirtuitos y calidad de cátodo en EW
          </p>
          <div className={classes.grow} />
          <img
            src={logoKreaHorizontal}
            alt='logoKreaHorizontal'
            style={{ width: '90px', margin: '0px 0px 0px 350px' }}
          />
        </Hidden>
        <div className={classes.grow} />
        <div>
          <img
            src={logoKreaHorizontal}
            alt='logoKreaHorizontal'
            style={{ width: '90px', margin: '0px' }}
          />
        </div>
      </Toolbar>
    </MUIAppBar>
  )
}

AppBar.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
}

export default AppBar
