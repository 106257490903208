import { createTheme } from '@material-ui/core'

const type = 'dark' // light

export default createTheme({
  palette: {
    type,
    primary: {
      main: '#d0650e',
      // contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#e39b26',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: type === 'dark' ? '#FFFFFF' : '#032D44',
    },
    background: {
      default: '#414141',
    },
  },
  typography: {
    fontFamily: ['Qanelas Soft', 'sans-serif'],
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  // typography: {
  //   fontFamily: '"Sans Pro", sans-serif',
  // },
})
