import React from 'react'
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
} from '@aws-amplify/ui-react'
import {
  AuthState,
  onAuthUIStateChange,
  Translations,
} from '@aws-amplify/ui-components'
import { I18n } from 'aws-amplify'
import { Grid } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import App from '../App'
import BackgroundStart from 'constants/images/BackgroundStart.png'
import TitleEvaSystem from 'constants/images/TitleEvaSystem.png'

I18n.setLanguage('es-CL')

I18n.putVocabulariesForLanguage('es-CL', {
  [Translations.EMAIL_LABEL]: 'Correo',
  [Translations.EMAIL_PLACEHOLDER]: 'usuario@kreamining.cl',
  [Translations.PASSWORD_LABEL]: 'Contraseña',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ingrese su contraseña',
  [Translations.RESET_PASSWORD_TEXT]: 'Recuperar Contraseña',
  [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvidó su contraseña?',
  [Translations.BACK_TO_SIGN_IN]: 'Volver',
  [Translations.SEND_CODE]: 'Enviar Codígo',
  [Translations.SIGN_OUT]: 'Log Out',
})

const AuthAmplifyApp = () => {
  const [authState, setAuthState] = React.useState()
  const [user, setUser] = React.useState()

  const authStyle = {
    width: '100vw',
    height: '100vh',
    background: `url(${BackgroundStart})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
  }

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <div className='App'>
      <Router>
        <App />
      </Router>
    </div>
  ) : (
    <AmplifyAuthenticator>
      <div slot='sign-in'>
        <Grid
          container
          direction='column'
          justifyContent='space-evenly'
          alignItems='center'
          style={authStyle}>
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#fff',
              padding: 0
            }}>
            <img
              src={TitleEvaSystem}
              alt='TitleEvaSystem'
              style={{ maxWidth: '60%', marginBottom: 10 }}
            />
            <AmplifySignIn
              hideSignUp
              headerText=''
              submitButtonText='ingresa'
              usernameAlias='email'
            />
          </Grid>
        </Grid>
      </div>
      <div slot='forgot-password'>
        <Grid
          container
          direction='column'
          justifyContent='space-evenly'
          alignItems='center'
          style={authStyle}>
          <Grid item>
            <AmplifyForgotPassword
              headerText='Recuperar Contraseña'
              usernameAlias='email'
            />
          </Grid>
        </Grid>
      </div>
    </AmplifyAuthenticator>
  )
}

export default AuthAmplifyApp
